<template>
  <div>
    <!--Title Bar-->
    <title-bar v-if="teacherSection === 1" :title-value="'Teachers'"></title-bar>

    <!-- Title Bar For Section List-->
    <v-card v-else flat>
      <v-toolbar dense flat color="secondary" class="mb-2" dark>
        <v-icon>fal fa-user-circle</v-icon>
        <v-toolbar-title class="pl-2">{{
          teacherSection === 3 ? "Bookmarked Teachers" : "Teachers"
        }}</v-toolbar-title>
      </v-toolbar>
    </v-card>

    <!-- Filter -->
    <v-toolbar v-if="teacherSection !== 3" flat dense>
      <v-menu v-model="filterMenu" offset-y right :close-on-content-click="false">
        <template #activator="{ on }">
          <v-btn rounded color="primary" v-on="on"
            ><v-icon small left>fal fa-filter</v-icon><span v-if="showFiltered()">Filtered</span
            ><span v-else>Filter</span>
          </v-btn>
        </template>

        <v-card width="600px">
          <v-card-text>
            <v-container fluid grid-list-sm>
              <!--Clear Filter Button -->
              <v-row dense>
                <v-col cols="12" sm="12" v-if="showFiltered()">
                  <v-btn block rounded color="primary" @click="clearFilter()">
                    <v-icon small left>fal fa-times</v-icon>Clear Filters
                  </v-btn>
                </v-col>
              </v-row>

              <v-row dense>
                <!--Teacher ID-->
                <v-col cols="12" sm="3">
                  <v-text-field
                    v-model="id"
                    label="Teacher ID"
                    placeholder=" "
                    persistent-placeholder
                  />
                </v-col>
              </v-row>
              <v-row dense>
                <!--Classroom Provider-->
                <v-col cols="12" sm="8">
                  <v-autocomplete
                    :items="providersStore.providersValueList"
                    label="Classroom Provider"
                    placeholder=" "
                    persistent-placeholder
                    item-text="d_display"
                    item-value="id"
                    v-model="providerID"
                    clearable
                    clear-icon="fal fa-times-circle"
                  />
                </v-col>
                <!--Classroom Provider Type-->
                <v-col cols="12" sm="4">
                  <v-autocomplete
                    :items="valueListsStore.valueListItems[27]"
                    label="Classroom Provider Type"
                    placeholder=" "
                    persistent-placeholder
                    item-text="d_name"
                    item-value="d_name"
                    v-model="providerPrekSetting"
                    clearable
                    clear-icon="fal fa-times-circle"
                  />
                </v-col>
              </v-row>
              <v-row dense>
                <!--Last Name-->
                <v-col cols="12" sm="6">
                  <v-text-field
                    v-model="d_nameLast"
                    label="Last Name"
                    placeholder=" "
                    persistent-placeholder
                  />
                </v-col>

                <!--First Name-->
                <v-col cols="12" sm="6">
                  <v-text-field
                    v-model="d_nameFirst"
                    label="First Name"
                    placeholder=" "
                    persistent-placeholder
                  />
                </v-col>
              </v-row>

              <v-row dense>
                <!--Position-->
                <v-col cols="12" sm="8">
                  <v-autocomplete
                    :items="valueListsStore.valueListItems[31]"
                    label="Position"
                    placeholder=" "
                    persistent-placeholder
                    item-text="d_name"
                    item-value="d_value"
                    v-model="f_type"
                    clearable
                    clear-icon="fal fa-times-circle"
                  />
                </v-col>
                <!--Substitute-->
                <v-col cols="12" sm="6" md="4">
                  <v-autocomplete
                    :items="valueListsStore.yesNo"
                    label="Substitute"
                    placeholder=" "
                    persistent-placeholder
                    item-text="value"
                    item-value="id"
                    v-model="f_substitute"
                    clearable
                    clear-icon="fal fa-times-circle"
                  />
                </v-col>
              </v-row>
              <v-row dense>
                <!--Alert-->
                <v-col cols="12" sm="12" md="8">
                  <v-select
                    :items="actionTypesStore.actionTypesValueList"
                    item-text="d_name"
                    item-value="id"
                    label="Alert"
                    placeholder=" "
                    persistent-placeholder
                    v-model="fk_actionTypeID"
                    clearable
                    clear-icon="fal fa-times-circle"
                  />
                </v-col>
                <!--Has No Open Alerts-->
                <v-col class="pt-0 pb-4" cols="12" sm="12" md="4">
                  <v-checkbox
                    v-model="noAlerts"
                    label="No Open Alerts"
                    color="primary"
                    class="pr-2"
                    hide-details
                  >
                  </v-checkbox>
                </v-col>
              </v-row>
              <v-row dense>
                <!--Status-->
                <v-col cols="12" sm="4">
                  <v-autocomplete
                    :items="valueListsStore.activeStatus"
                    label="Status"
                    placeholder=" "
                    persistent-placeholder
                    item-text="value"
                    item-value="id"
                    v-model="f_status"
                    clearable
                    clear-icon="fal fa-times-circle"
                  />
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>

          <v-card-actions>
            <!--Submit Button-->
            <v-btn block rounded color="primary" @click="filter()">
              <v-icon small left>fal fa-check</v-icon>Submit
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-menu>

      <v-spacer />

      <export-excel
        :section="6"
        :enabled="teachersStore.teachers.data && teachersStore.teachers.data.length > 0"
      />
      <!--Teacher Pop Up Dialog-->
      <teacher-entry :teacher-section="1" />
    </v-toolbar>

    <!--Pagination  -->
    <pagination
      :show="teachersStore.teachers.data && teachersStore.teachers.data.length > 0"
      :showTotal="teacherSection === 3 ? false : true"
      :currentPage="teachersStore.teachers.current_page"
      :lastPage="teachersStore.teachers.last_page"
      :total="teachersStore.teachers.total"
      v-on:paginate-event="filter($event)"
    />

    <div v-if="progressStore.progress !== true">
      <!--List-->
      <list-to-detail
        :show="teachersStore.teachers.data && teachersStore.teachers.data.length > 0 ? true : false"
        :list-data="teachersStore.teachers.data"
        :title="'Teachers'"
        :section="5"
        :showid="true"
        @open-detail="detail($event)"
      />
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { createHelpers } from "vuex-map-fields";
import { mixin } from "@/mixins/mixin.js";
import TitleBar from "@/components/TitleBar";
import Pagination from "@/components/Pagination";
import ListToDetail from "@/components/ListToDetail";
import ExportExcel from "@/components/ExportExcel";
import TeacherEntry from "@/components/TeacherEntry";

const { mapFields } = createHelpers({
  getterType: "teachersStore/getFilterField",
  mutationType: "teachersStore/updateFilterField"
});

export default {
  name: "Teachers",
  components: {
    TitleBar,
    Pagination,
    ListToDetail,
    ExportExcel,
    TeacherEntry
  },
  mixins: [mixin],
  computed: {
    ...mapState({
      usersStore: state => state.usersStore,
      teachersStore: state => state.teachersStore,
      progressStore: state => state.progressStore,
      valueListsStore: state => state.valueListsStore,
      providersStore: state => state.providersStore,
      actionTypesStore: state => state.actionTypesStore
    }),
    ...mapFields([
      "page",
      "section",

      "id",
      "d_nameLast",
      "d_nameFirst",
      "d_nameDisplay",
      "f_type",
      "f_substitute",
      "fk_actionTypeID",
      "noAlerts",
      "f_status",
      "providerPrekSetting",
      "providerID",
    ])
  },
  props: {
    /* All = 1, Dashboard = 2 */
    teacherSection: {
      type: Number,
      required: false
    }
  },
  created() {
    this.initialize();
  },
  data() {
    return {
      progress: true,
      filterMenu: false,
      menu: false
    };
  },
  methods: {
    async initialize() {
      /* Show progress bar if not running from dashboard */
      if (this.teacherSection === 1) {
        this.$store.dispatch("progressStore/set", true);
      }
      await this.changeSectionCheck();

      //const user = this.$store.getters["usersStore/getAuthUser"];
      //this.f_viewPrivateRecords = user.f_viewPrivateRecords;

      /* Pull teacher list */
      const filters = this.$store.getters["teachersStore/getFilters"];
      await this.$store.dispatch("teachersStore/fetch", filters);
      /* Pull provider value list */
      await this.$store.dispatch("providersStore/valueList");

      this.$store.dispatch("progressStore/set", false);
    },

    async loadValueLists() {
      await this.$store.dispatch("yearsStore/valueList");
      const vldata = {
        id: [27, 31]
      };
      await this.$store.dispatch("valueListsStore/items", vldata);
      const f_type = {
          f_type: 3
        };
      await this.$store.dispatch("actionTypesStore/valueList", f_type);
    },

    async filter(page) {
      this.filterMenu = false;
      this.page = page;
      this.initialize();
    },

    clearFilterColumns() {
      this.id = "";
      this.providerID = "";
      this.providerPrekSetting = "";
      this.d_nameFirst = "";
      this.d_nameLast = "";
      this.f_type = "";
      this.f_substitute = "";
      this.fk_actionTypeID = "";
      this.noAlerts = false;
      this.f_status = "";
      this.page = 1;
    },

    async changeSectionCheck() {
      await this.loadValueLists();
      if (this.teacherSection !== this.section) {
        this.clearFilterColumns();
        this.$store.dispatch("teachersStore/clearteachers");
      }
      /* Save section in store */
      this.section = this.teacherSection;
    },

    async clearFilter() {
      this.filterMenu = false;
      this.clearFilterColumns();
      this.initialize();
    },

    showFiltered() {
      if (
        this.id ||
        this.providerID ||
        this.providerPrekSetting ||
        this.d_nameFirst ||
        this.d_nameLast ||
        this.f_type ||
        this.f_substitute ||
        this.f_substitute === 0 ||
        this.fk_actionTypeID ||
        this.noAlerts == true ||
        this.f_status ||
        this.f_status === 0
      ) {
        return true;
      }
      return false;
    },

    detail(uuid) {
      this.$store.dispatch("teachersStore/currentIndex", uuid);
      this.$router.push({ name: "teacher", params: { teacherUUID: uuid } });
    }
  }
};
</script>

<style scoped>
.clickable {
  cursor: pointer;
}
button {
  outline: none;
}
</style>
