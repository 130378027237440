<template>

<div>

  <Teachers :teacherSection="1" />

</div>

</template>

<script>
import Teachers from '@/components/Teachers';

export default {
  name: 'TeachersList',
  components: {
    Teachers,
  },
};
</script>

<style scoped>

</style>
